<template>
  
  <ValidationObserver ref="formstepA" v-slot="{ handleSubmit, valid, passed}"
    id="settings_accountPAgeZona_a"
    tag="div" style="flex: 1 1 0%;" 
  >
    <q-form
      ref="myFormChgPass"
      @submit.prevent="handleSubmit(doSaveDataAccount)"
      @reset="onNewAccountReset"
      class=""
      style="margin-top:0px;height: 100%;"
    >

      <pageHeader
        :showSubtitle="false"
        :showTitleAltZone="true"
        :showTlbZone="false"
        :cls_title_altZone="'pruebaAltZonewwww'"
        class="canna_settingsPageHeader"
      >
        <slot>
          <div class="demozzzwww" style="width: calc(100% - 10px);position: relative;">
            <div>
              Contraseña
            </div>
          </div>
        </slot>
        <template v-slot:altZone>
          <q-btn color="app_mainBtn" label="Guardar cambios" no-caps unelevated type="submit"
            :disable="((passed || valid))? false : true"
          />
        </template>
      </pageHeader>

      <div id="settings_accountPageZona_contents" class="canna_settings_pageZoneContents" style="overflow: auto;">

        <div class="settings_accountZoneContainer_a" style="width: calc(100% - 48px);">
          <!-- conenedor de formulario -->
          <div class="canna_account_zone canna_account_zone_alt">
            <div class="canna_formZone canna_formZoneContainer" style="border-bottom: 0px solid rgb(241, 241, 244);">

                  <div class="canna_forma_zona" style="margin-top: 0px;">

                    <!-- Campo de contraseña actual -->
                    <ValidationProvider rules="required|cannaTextPasswordAlt|emojiFree|min:8|max:12" name="currentPassword" :immediate="false" v-slot="{ errors, invalid, validated  }">
                      <div class="canna_forma_campo_container">
                        <div style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel text-left"
                            :class="{
                              'fieldError':invalid && validated
                            }"
                          >Contraseña actual</div>
                          <div class="requiredLabel text-right" >
                            Obligatorio
                          </div>
                        </div>
                        <q-input
                          filled
                          :type="(formData.inputPass===true) ? 'password' : 'text'"
                          name="currentPassword"
                          v-model="formData.currpassword"
                          hint=""
                          :dense="true"
                          :label="(formData.currpassword==='')?'Escribe tu contraseña':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ?  errors[0].replace('currentPassword','La contraseña actual').replace('El campo','') : ''"
                          :disabled="processingData"
                          @blur="pasox_onBlurField('currentPassword', $event)"
                        >
                          <template v-slot:append>
                            <div class="inputLabelAction noselect action_item" @click.stop="changeInpPass('inputPass')"
                              v-show="(formData.currpassword.length>=1) ? true : false "
                            >
                              {{(formData.inputPass===true) ? 'MOSTRAR' : 'OCULTAR'}}  <!-- <q-icon name="r_star" /> -->
                            </div>
                          </template>

                        </q-input>
                      </div>
                    </ValidationProvider>

                    <div style="border-bottom: 1px solid rgb(241, 241, 244);">
                      &nbsp;
                    </div>

                    <!-- Campo de Nueva contraseña -->
                    <ValidationProvider rules="required|cannaTextPasswordAlt|emojiFree|min:8|max:12" name="newpass" :immediate="false" v-slot="{ errors, invalid, validated  }">
                      <div class="canna_forma_campo_container spacerRowTop32">
                        <div style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel text-left"
                            :class="{
                              'fieldError':invalid && validated
                            }"
                          >Nueva contraseña</div>
                          <div class="requiredLabel text-right" >
                            Obligatorio
                          </div>
                        </div>
                        <q-input
                          filled
                          :type="(formData.inputPassB===true) ? 'password' : 'text'"
                          name="newpass"
                          v-model="formData.newpass"
                          hint=""
                          :dense="true"
                          :label="(formData.newpass==='')?'Escribe tu contraseña':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ?  errors[0].replace('newpass','La nueva contraseña').replace('El campo','') : ''"
                          :disabled="processingData"
                          @blur="pasox_onBlurNewPass('newpass', $event)"
                        >
                          <template v-slot:append>
                            <div class="inputLabelAction noselect action_item" @click.stop="changeInpPass('inputPassB')"
                              v-show="(formData.newpass.length>=1) ? true : false "
                            >
                              {{(formData.inputPassB===true) ? 'MOSTRAR' : 'OCULTAR'}}  <!-- <q-icon name="r_star" /> -->
                            </div>
                          </template>

                        </q-input>
                      </div>
                    </ValidationProvider>

                    <div class="canna_forma_campo_container spacerRowTop17">
                      <div  style="font-size: 11px; line-height: 1; color: rgba(0, 0, 0, 0.54); text-align: left;" >
                        <div>
                          <div>
                            Tu contraseña debe tener entre 8 y 12 caracteres. Para que sea segura debes incluir en ella números, 
                            letras y signos de puntuación como: &#10095; - _ . ! * $ % &#38; / # ? ) = ¿ + ¡ ( &#10094;
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Campo de Confirmacion de contraseña -->
                    <ValidationProvider rules="required|cannaTextPasswordConfAlt:@newpass|emojiFree|min:8|max:12" name="confPass" :immediate="false" v-slot="{ errors, invalid, validated  }">
                      <div class="canna_forma_campo_container spacerRowTop24">
                        <div style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel text-left"
                            :class="{
                              'fieldError':invalid && validated
                            }"
                          >Confirmar nueva contraseña</div>
                          <div class="requiredLabel text-right" >
                            Obligatorio
                          </div>
                        </div>
                        <q-input
                          filled
                          :type="(formData.inputPassC===true) ? 'password' : 'text'"
                          name="confPass"
                          v-model="formData.confPass"
                          hint=""
                          :dense="true"
                          :label="(formData.confPass==='')?'Escribe tu contraseña':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ?  errors[0].replace('confPass','La confirmación de nueva contraseña').replace('El campo','') : ''"
                          :disabled="processingData"
                          @blur="pasox_onBlurNewPass('confPass', $event)"
                        >
                          <template v-slot:append>
                            <div class="inputLabelAction noselect action_item" @click.stop="changeInpPass('inputPassC')"
                              v-show="(formData.confPass.length>=1) ? true : false "
                            >
                              {{(formData.inputPassC===true) ? 'MOSTRAR' : 'OCULTAR'}}  <!-- <q-icon name="r_star" /> -->
                            </div>
                          </template>

                        </q-input>
                      </div>
                    </ValidationProvider>

                    <template v-if="(frmMainErrors.formstepA !== '')">
                      <div class="canna_frmMainErrorZone spacerRowTop24" style="display: flex;flex-direction: row;align-items: center;">
                        <div style="margin-right:10px;">
                          <img src="images/login_warning.png"/>
                        </div>
                        <div style="flex:1;" v-html="frmMainErrors.formstepA"></div>
                      </div>
                    </template>


                  </div>

            </div>

          </div>

        </div>

      </div>
    </q-form>

    <!-- Loaders --> 
    <q-inner-loading :showing="loading_Process" class="app_innerLoader" style="top: 0px; z-index: 9000;position: fixed;">
      <q-spinner-orbit size="100px" color="indigo" />
    </q-inner-loading>

    <q-inner-loading :showing="zloadingData" style="top: 0px; z-index: 9000;position: fixed;">
      <q-spinner-gears :size="(pageSize === 'sm' || pageSize === 'xs') ? '90px' : '120px' " color="cannaLoading" />
    </q-inner-loading>

  </ValidationObserver>

</template>

<script>
import pageHeader from 'components/layout/headerPage'

import { requestPack } from './../../boot/axios' // APP code 
import { canna_cookieMethods } from './../../boot/__cannav2_app'

import logOut_Mixin from './../../mixins/requestMix.js'
import myplatform_Mixin from './../../mixins/platformMix.js'
import helperBase_Mixin from './../../mixins/helperBase.js'

export default {
  name: 'settingsPage_PassAccount',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin
  ],
  props:{

  },
  components: {
    // cxIcon: require('components/appIcons.vue').default,
    // cxBackground: require('components/appBackgrounds').default,
    pageHeader
  },  
  data () { 
    return {
      version: '1.0.1',
      debugEls: false,
      initCmp: false, 
      zinitScreen: false,
      loading_Process: false,
      zloadingData: false,
      processingData: false,
      frmMainErrors:{
        formstepA: 'Las contraseñas ingresadas no coinciden.'
      },
      formData:{
        inputPass:true,
        inputPassB:true,
        inputPassC:true,
        currpassword:'',
        newpass:'',
        confPass:''
      },
      frmMainRefs:{
        formstepA: [
          {fieldNameAPi: 'currentPassword', fieldNameForm: 'currentPassword' },
          {fieldNameAPi: 'newPassword', fieldNameForm: 'newpass' },
          {fieldNameAPi: 'newPasswordConfirm', fieldNameForm: 'confPass' }
        ]
      }
    }
  },
  computed: {
    routeName () {
      return this.$route.name
    }
  },
  watch: {
    'formData.currpassword': function (newVal, oldVal) {
      if (newVal!==oldVal){ 
        if (newVal==='' || newVal===null){
          this.formData.inputPass = true
        }
      }
    },
    'formData.newpass': function (newVal, oldVal) {
      if (newVal!==oldVal){ 
        if (newVal==='' || newVal===null){
          this.formData.inputPassB = true
        }
      }
    },
    'formData.confPass': function (newVal, oldVal) {
      if (newVal!==oldVal){ 
        if (newVal==='' || newVal===null){
          this.formData.inputPassC = true
        }
      }
    }
  },
  methods: {
    ...requestPack, 
    ...canna_cookieMethods,
    onNewAccountReset () {
      this.formData = {
        inputPass:true,
        inputPassB:true,
        inputPassC:true,
        currpassword:'',
        newpass:'',
        confPass:''
      }
      this.frmMainErrors.formstepA = ''
      if (this.$refs.formstepA) {
        this.$refs.formstepA.reset()
      }
      this.$forceUpdate()
    },
    onNewAccountSubmit () { 

    },
    changeInpPass (nameProp){
      this.formData[nameProp] = !this.formData[nameProp]
      this.$forceUpdate() 
    },
    pasox_onBlurField () { 
      if(this.frmMainErrors.formstepA !== ''){
        this.frmMainErrors.formstepA = ''
      }
      this.$forceUpdate()
    },
    pasox_onBlurNewPass ( nameField, event){
      if (this.formData.newpass!== '' && this.formData.confPass!== ''){ 
        this.frmMainErrors.formstepA = (this.formData.newpass ===  this.formData.confPass) ? '' : 'Las contraseñas ingresadas no coinciden.'
      } else {
        this.frmMainErrors.formstepA = ''
      }
      this.$forceUpdate()
    },
    async doSaveDataAccount (event) {
      this.$cannaDebug('-- settingsAccount --  doSaveDataAccount')
      var test = 11
      // -------------
      var profileData =  JSON.parse(JSON.stringify(this.getProfileData))
      var mycfgData = {
        currentPassword: this.formData.currpassword, 
        newPassword: this.formData.newpass , 
        newPasswordConfirm: this.formData.confPass 
      }
      //--------------
      var mcModel = this.getModelRequest('profileChgPassx') 
      var mloginModel = this.getModelRequest('_userlogin')
      var mpModel = this.getModelRequest('userProfile')

      var reqRes = { action: [], login: [], profile: [], profileEdit:[] }
      var reqErr = { action: 0, login: 0, profile: 0, profileEdit:0 }

      this.loading_Process = true 

      var myProfileEditCfg = this.createAxiosConfig ('patch', mcModel, { useData:false, data: mycfgData } , true, null)
      await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.action, myProfileEditCfg).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })

      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 44
        this.ex_handlerErrorFormRequest (reqRes.action[0], reqErr.action, this.$refs.formstepA, 'formstepA',  this.frmMainRefs.formstepA , 'formstepA')
        this.loading_Process = false
        this.$forceUpdate()
        return false 
      }
      await this._waitRequestProc(200) // Dejamos q se actualize en el server bien 

      // Paso bien el cambio de Contraseña...! 

      var myLogConfig = this.createAxiosConfig ('post', mloginModel, { 
        useData: true ,
        data: {
          grant_type: 'password',
          username: '' + profileData.email,
          password: '' + this.formData.newpass
        }
      }, false, null)

      await this.doRequestByPromise(this.$axios, 'userlogin', reqRes.login, myLogConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.login[reqRes.login.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.login += 1
        }
      })
      if (reqErr.login > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.loading_Process = false
        return false
      }

      await this._waitRequestProc(100)
      var loginData = reqRes.login[0].data.data
      var myToken = loginData.access_token
      this.$store.commit('myapp/setloginData', { token: myToken }) //setloginData
      this.$store.commit('myapp/setAppUserData', { access_token: myToken })
      await this._waitRequestProc(100)

      var dataObject = { logIn:true, user: profileData.id, token: myToken}
      var testCks = this.cannack_setCookieData (this.$q.cookies, dataObject)
      this.$cannaDebug('-- accountIndexScreen -- ckupdate -- ' + testCks)
      test = 77

      this.loading_Process = false
      this.onNewAccountReset() 

      this.$q.notify({
        position: 'top',
        color:'cannaOKAlert',
        type: 'positive',
        message: 'La contraseña fue modificada exitosamente',
        timeout: 7000 
      })

      return true
    },
  },
  // component especific
  beforeCreate () {
    this.$cannaDebug('-- settingsPass --  beforeCreate')
  },
  created () {
    this.$cannaDebug('-- settingsPass --  created')
    this.initCmp = false
  },
  beforeMount () {
    // this.$cannaDebug('-- settingsPass --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- settingsPass --  mounted')
    this.initCmp = true
    this.$forceUpdate()
  },
  beforeUpdate () {
    // this.$cannaDebug('-- settingsPass --  beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- settingsPass --  updated')
  },
  activated () {
    this.$cannaDebug('-- settingsPass --  activated')
    if ( this.initCmp = true) {
      this.onNewAccountReset()
    }
  },
  deactivated () {
    // this.$cannaDebug('-- settingsPass --  deactivated')
    this.onNewAccountReset()
  },
  beforeDestroy () {
    this.$cannaDebug('-- settingsPass --  beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- settingsPass --  destroyed')
  }
}
</script>
